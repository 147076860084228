<template>
  <div>
    <div style="font-size: 40px;color: #02a7f0;font-weight: bold;text-align:left;">AI答疑管理平台</div>
    <el-divider style="margin: 15px 0;"></el-divider>
    <div class="question-detail">
      <div style="font-size: 32px;color: #000000;font-weight: bold;text-align:left;margin-left: 1.8%;margin-bottom: 1%;">AI答复详情</div>
      <el-row>
        <el-col :span="12">
          <el-card class="box-card" ref="card1">
            <div slot="header" class="clearfix">
              <span>试题信息</span>
            </div>
            <el-scrollbar style="height: 600px">
              <div class="question-info" style="text-align: left;">
                <p>试题ID：{{ tableData.testID }}</p>
                <p>试题目录：{{ subject_path }}</p>
                <p>试题题干：{{ tableData.testQuestionStem }}</p>
                <div>试题选项：
                  <p v-for="i in tableData.testOptions">&nbsp;&nbsp;&nbsp;{{ i }}<br></p>
                </div>
                <p>答案：{{ tableData.answer }}</p>
                <p>解析：{{ tableData.analysis }}</p>
              </div>
            </el-scrollbar>
          </el-card>
        </el-col>

        <el-col :span="12">
          <el-card class="box-card" ref="card2">
            <div slot="header" class="clearfix">
              <span>用户提问信息&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ messagesTime }}</span>
            </div>
            <el-scrollbar style="height: 600px;">
              <div class="user-questions" style="text-align: left;">
                <div v-for="mes in messages">
                  {{ mes.role }}：{{ mes.content }}
                  <div v-if="mes.role === 'AI回复'" style="margin-bottom: 26px;">
                    <div style="text-align: right;">
                      <el-tag v-if="mes.status === 1" type="success">已采纳</el-tag>
                      <el-tag v-if="mes.status === 2" type="info">未评价</el-tag>
                      <el-tag v-if="mes.status === 0" type="danger">不采纳</el-tag>
                      &nbsp;&nbsp;
                      <el-popover
                          placement="bottom"
                          width="500"
                          trigger="click"
                          v-if="mes.role === 'AI回复'">
                        <div>
                          <h3>AI推荐问题</h3>
                          暂无推荐问题<br>
                        </div>
                        <el-button slot="reference" type="primary" size="small" icon="el-icon-edit" plain title="推荐问题"></el-button>
                      </el-popover>
                    </div>
                  </div>
                  <div style="margin-bottom: 12px;" v-else>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  getQADetails,
} from '../../API/api.js'

export default {
  props: ['info_id'],
  created() {
    getQADetails({
      pid: this.info_id
    }).then(res => {
      this.tableData = res.data.test
      this.subject_path = res.data.path.first + '/' + res.data.path.second + '/' + res.data.path.cname
      this.messages = res.data.messages
      this.messagesTime = res.data.messages[0].messagesTime
    })
  },
  data() {
    return {
      subject_path: '',
      messagesTime: '',
      tableData: [],
      messages: [],
      content_style: {
        'font-size': '16px'
      },
    }
  }
}
</script>
<style>
.box-card {
  width: 98%;
  margin-left: 1%;
  //min-height: 700px;
  //height: 100%;
  height: 700px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-scrollbar__thumb {
  background: transparent;
}


.clearfix {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.lab {
  font-size: large
}
</style>
