<template>
  <div>
    <div style="font-size: 40px;color: #02a7f0;font-weight: bold;text-align:left;">AI答疑管理平台</div>
    <el-divider style="margin: 15px 0;"></el-divider>
    <el-form :inline="true" :model="form" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="选择考试类型" prop="className">
        <el-select v-model="form.className" placeholder="请选择" @change="onClassNameChange">
          <el-option label="全部" value="-1"></el-option>
          <!--          <el-option :label="key" :value="key" v-for="key in Object.keys(array_map1)"></el-option>-->
          <el-option :label="key" :value="key" v-for="key in ['正高卫生职称','副高卫生职称','中级卫生职称','初级卫生职称', '执业药师']"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="examType">
        <el-select v-model="form.examType" placeholder="请选择">
          <el-option label="全部" value="-1"></el-option>
          <el-option :label="key" :value="key" v-for="key in array_map1[form.className]"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择科目" prop="appEName">
        <el-select v-model="form.appEName" placeholder="请选择">
          <el-option label="全部" value="-1"></el-option>
          <el-option :label="key.cname" :value="key.appename" v-for="key in array_map2[form.className + form.examType]"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="使用时间段" prop="dateRange">
        <el-date-picker
            v-model="form.dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search" icon="el-icon-search">查找</el-button>
        <el-button @click="reset" icon="el-icon-refresh">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
        border
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        @row-click="handleRowClick"
        tooltip-effect="light"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="index" label="序号" width="80"></el-table-column>
      <el-table-column prop="userId" label="用户ID" width="120"></el-table-column>
      <el-table-column prop="useTime" label="使用时间" width="180"></el-table-column>
      <el-table-column prop="userQuestion" label="用户提问" width="320"></el-table-column>
      <el-table-column prop="aiAnswer" label="AI答复" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-link :underline="false" @click="get_dialog_details">{{ scope.row.aiAnswer }}</el-link>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination background layout="total, prev, pager, next" :page-count="max_page_number" :total="max_number" :page-size="form.numberOfItems"
                     @current-change="currentChange"
                     :current-page="currentPage">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="90%" top="3%">
      <div slot="title" style="font-size: 32px;color: #000000;font-weight: bold;text-align:left;margin-left: 1.8%;margin-bottom: -1%">AI答复详情</div>
      <div class="question-detail">
        <el-row>
          <el-col :span="12">
            <el-card class="box-card" ref="card1">
              <div slot="header" class="clearfix">
                <span>试题信息</span>
              </div>
              <el-scrollbar style="height: 600px">
                <div class="question-info" style="text-align: left;">
                  <p>试题ID：{{ dialog_tableData.testID }}</p>
                  <p>试题目录：{{ subject_path }}</p>
                  <p>试题题干：{{ dialog_tableData.testQuestionStem }}</p>
                  <div>试题选项：
                    <p v-for="i in dialog_tableData.testOptions">&nbsp;&nbsp;&nbsp;{{ i }}<br></p>
                  </div>
                  <p>答案：{{ dialog_tableData.answer }}</p>
                  <p>解析：{{ dialog_tableData.analysis }}</p>
                </div>
              </el-scrollbar>
            </el-card>
          </el-col>

          <el-col :span="12">
            <el-card class="box-card" ref="card2">
              <div slot="header" class="clearfix">
                <span>用户提问信息&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ messagesTime }}</span>
              </div>
              <el-scrollbar style="height: 600px;">
                <div class="user-questions" style="text-align: left;">
                  <div v-for="mes in messages">
                    {{ mes.role }}：{{ mes.content }}
                    <div v-if="mes.role === 'AI回复'" style="margin-bottom: 26px;">
                      <div style="text-align: right;">
                        <el-tag v-if="mes.status === 1" type="success">已采纳</el-tag>
                        <el-tag v-if="mes.status === 2" type="info">未评价</el-tag>
                        <el-tag v-if="mes.status === 0" type="danger">不采纳</el-tag>
                        &nbsp;&nbsp;
                        <el-popover
                            placement="bottom"
                            width="300"
                            trigger="click"
                            v-if="mes.role === 'AI回复'">
                          <div>
                            <h3>AI推荐问题</h3>
                            暂无推荐问题<br>
                          </div>
                          <el-button slot="reference" type="primary" size="small" icon="el-icon-edit" plain title="推荐问题"></el-button>
                        </el-popover>
                      </div>
                    </div>
                    <div style="margin-bottom: 12px;" v-else>
                    </div>
                  </div>
                </div>
              </el-scrollbar>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getLevel, getQADetails, getQAList} from '../../API/api.js'

export default {
  methods: {
    onClassNameChange() {
      this.form.examType = '-1';
      this.form.appEName = '-1';
    },
    reset() {
      this.$refs.form.resetFields();
    },
    search() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.numberOfPages = 1;
          this.currentPage = 1;

          const loading = this.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });

          getQAList(this.form).then(res => {
            this.tableData = res.data.data;
            this.max_number = res.data.number;
            this.max_page_number = res.data.pages;

            loading.close()

            this.$message({
              message: '查询成功!',
              type: 'success'
            })
          })
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
    handleRowClick(row) {
      const info_id = row.infoId;

      getQADetails({
        pid: info_id
      }).then(res => {
        this.dialog_tableData = res.data.test
        this.subject_path = res.data.path.first + '/' + res.data.path.second + '/' + res.data.path.cname
        this.messages = res.data.messages
        this.messagesTime = res.data.messages[0].messagesTime
      })

      this.dialogVisible = true;
      // const url = this.$router.resolve({
      //   name: 'Info',
      //   params: {info_id}
      // }).href;
      // window.open(url, '_blank'); // 打开新标签页
    },
    currentChange(page) {
      this.currentPage = page;
      this.form.numberOfPages = page;
      this.$refs.form.validate((valid) => {
        if (valid) {
          getQAList(this.form).then(res => {
            this.tableData = res.data.data;
            this.max_number = res.data.number;
            this.max_page_number = res.data.pages;
          })
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
  },
  created() {
    getLevel({}).then(res => {
      console.log(res)
      this.array_map1 = res.data.first
      this.array_map2 = res.data.second
    })
  },
  data() {
    return {
      currentPage: 1,
      max_number: 0,
      max_page_number: 1,
      array_map1: [],
      array_map2: [],
      tableData: [],
      dialogVisible: false,
      form: {
        className: '',
        examType: '',
        appEName: '',
        dateRange: '',
        numberOfItems: 15,
        numberOfPages: 1,
      },
      subject_path: '',
      messagesTime: '',
      dialog_tableData: [],
      messages: [],
      content_style: {
        'font-size': '16px'
      },
      rules: {
        className: [
          {required: true, message: '请选择考试类型', trigger: 'blur'}
        ],
        examType: [
          {required: true, message: '请选择考试子类型', trigger: 'blur'}
        ],
        appEName: [
          {required: true, message: '请选择科目', trigger: 'blur'}
        ],
        dateRange: [
          {type: 'array', required: true, message: '请选择时间段', trigger: 'blur'}
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          },
          // {
          //   text: '今天',
          //   onClick(picker) {
          //     picker.$emit('pick', new Date());
          //   }
          // }
        ],
        disabledDate(time) {
          const startDate = new Date(new Date().getFullYear(), 4, 22).getTime(); // 5月22日
          const endDate = new Date().getTime(); // 今天
          return time.getTime() < startDate || time.getTime() > endDate;
        }
      }
    }
  }
}
</script>
<style>
.el-divider--horizontal {
  margin: 20px 0;
}

.el-tooltip__popper {
  font-size: 16px;
  max-width: 60%;
  display: none;
}

.box-card {
  width: 98%;
  margin-left: 1%;
  font-size: 16px;
  //min-height: 700px;
  //height: 100%;
  height: 700px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}

.el-scrollbar__thumb {
  background: transparent;
}


.clearfix {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
