//http.js文件用来封装我们的axios

import axios from 'axios'

const service = axios.create({
    // baseURL: 'http://172.16.2.101:8098',
    baseURL: 'https://aiqamanagementplatform.cpolar.top',

    // 超时时间 单位是ms，这里设置了3s的超时时间
    timeout: 10 * 1000
})

service.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    return response
}, error => {
    Promise.reject(error)
})

export default service
