import http from '../axios/http.js'

export function getLevel(params) {
    return http.get('api/getLevel', params)
}

export function getQAList(params) {
    return http.post('api/getQAList', params)
}

export function getQADetails(params) {
    return http.get('api/getQADetails', params)
}